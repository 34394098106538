import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const addVod = (vod) => {
  return ajax.post(`${base}/api/itedu/v1/doge-cloud-vod/add`, vod);
};

const getVod = (id) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud-vod/get?id=${id}`);
};

const listVod = (start, len) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud-vod/pager?start=${start}&len=${len}`);
};

const deleteVod = (id) => {
  return ajax.post(`${base}/api/itedu/v1/doge-cloud-vod/delete?id=${id}`);
};

const searchVod = (text) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud-vod/query/search?text=${text}`);
};

export const dogeVodApi = {
  addVod: addVod,
  getVod: getVod,
  listVod: listVod,
  deleteVod: deleteVod,
  searchVod: searchVod,
};
