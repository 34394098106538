<template>
  <section>
    <!--工具条-->
    <el-col :span="20" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="8">
          <el-form-item label="文件名">
            <el-input v-model="queryText" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="query">查询</el-button>
          <el-button @click="query">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="vodList" highlight-current-row style="width: 90%">
      <el-table-column prop="id" label="id" width="200"></el-table-column>
      <el-table-column prop="vcode" label="vcode" width="200"></el-table-column>
      <el-table-column prop="fileName" label="名称" width="200"></el-table-column>
      <el-table-column prop="updateTime" label="updateTime" width="200" :formatter="formatTime"></el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-button size="small" @click="onViewVod(scope.$index, scope.row)">预览</el-button>
          <el-button size="small" @click="onEditVod(scope.$index, scope.row)">编辑</el-button>
          <el-button type="danger" size="small" @click="onDeleteVod(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="20" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { dogeVodApi } from "@/api/dogeVodApi";
import msg from "@/util/msg.js";
import { epochToDateString } from "@/util/TimeUtil";

const PAGE_SIZE = 20;

export default {
  data() {
    return {
      vodList: [],
      queryText: "",
      currentPage: 1,
      totalPage: 1,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    search() {
      dogeVodApi
        .searchVod(this.queryText)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.vodList = data;
          }
        })
        .catch((err) => {
          console.log("搜索失败", err);
          msg.error("搜索失败");
        });
    },

    query() {
      if (this.queryText) {
        this.search();
        return;
      }
      let start = (this.currentPage - 1) * PAGE_SIZE;
      if (start < 0) {
        start = 0;
      }
      dogeVodApi
        .listVod(start, PAGE_SIZE)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.vodList = data;
          }
        })
        .catch((err) => {
          this.$message({
            message: "获取vod失败" + err,
            type: "error",
          });
        });
    },
    onChangePage(val) {
      this.currentPage = val;
      console.log(`query 第${val}页`);
      this.query();
    },
    onViewVod(index, row) {
      let path = "/vod/doge-view?vcode=" + row.vcode;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onEditVod(index, row) {
      this.$router.push({
        name: "doge-add",
        query: { id: row.id },
      });
    },
    onDeleteVod(index, row) {
      console.log(index, row);
      const id = row.id;
      this.$confirm("确定删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        dogeVodApi.deleteVod(id).then((ret) => {
          if (ret.code == 0 && ret.data) {
            msg.succeed("删除成功");
            this.query();
          }
        });
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.toolbar {
  margin: 30px;
}
</style>
